<template>
  <div class="patentSearch">
    <nf-breadcrumb
      :breadList="[{ name: '知识产权' }, { name: '专利检索' }]"
    ></nf-breadcrumb>
    <div class="wrap">
      <div class="titleWrap">
        <div class="title">专利检索</div>
        <div class="EnTitle">Global Patent Search</div>
        <div class="nav">
          <span
            @click="handleSwitch(index)"
            :class="item.isChecked ? 'active' : ''"
            v-for="(item, index) in navList"
            :key="index"
            >{{ item.text }}</span
          >
        </div>
      </div>
      <div class="searchWrap">
        <div class="bgWrap">
          <el-input
            placeholder="请输入关键字"
            v-model="params.kw"
            class="input-with-select"
          >
            <div
              @mouseenter="showArea = true"
              @mouseleave="showArea = false"
              class="areaFiltration"
              slot="prepend"
            >
              地域筛选 ▼
            </div>
            <el-button
              @click="toPatentList"
              slot="append"
              icon="el-icon-search"
            ></el-button>
          </el-input>
          <div
            @mouseenter="showArea = true"
            @mouseleave="showArea = false"
            class="areaWrap"
            v-show="showArea"
          >
            <el-row>
              <el-col :span="5">
                <el-checkbox
                  v-model="checkAll_all"
                  @change="handleCheckAllChange_all"
                  >全球</el-checkbox
                >
              </el-col>
              <el-col :span="19">数据范围</el-col>
            </el-row>
            <el-row v-show="showCheck">
              <el-col :span="5"
                ><el-checkbox
                  v-model="checkAll_one"
                  @change="handleCheckAllChange_one"
                  >中国</el-checkbox
                ></el-col
              >
              <el-col :span="19">
                <el-checkbox-group
                  v-model="params.checked_one"
                  @change="handleCheckedAreaChange_one"
                >
                  <el-checkbox
                    v-for="area in area_one"
                    :label="area.value"
                    :key="area.value"
                    >{{ area.name }}</el-checkbox
                  >
                </el-checkbox-group>
              </el-col>
            </el-row>
            <el-row v-show="showCheck">
              <el-col :span="5"
                ><el-checkbox
                  v-model="checkAll_two"
                  @change="handleCheckAllChange_two"
                  >主要国家和地区</el-checkbox
                ></el-col
              >
              <el-col :span="19">
                <el-checkbox-group
                  v-model="params.checked_two"
                  @change="handleCheckedAreaChange_two"
                >
                  <el-checkbox
                    v-for="area in area_two"
                    :label="area.value"
                    :key="area.value"
                    >{{ area.name }}</el-checkbox
                  >
                </el-checkbox-group>
              </el-col>
            </el-row>
            <el-row v-show="showCheck">
              <el-col :span="5"
                ><el-checkbox
                  v-model="checkAll_three"
                  @change="handleCheckAllChange_three"
                  >其他国家和地区</el-checkbox
                ></el-col
              >
              <el-col :span="19">
                <el-checkbox-group
                  v-model="params.checked_three"
                  @change="handleCheckedAreaChange_three"
                >
                  <el-checkbox
                    v-for="area in area_three"
                    :label="area.value"
                    :key="area.value"
                    >{{ area.name }}</el-checkbox
                  >
                </el-checkbox-group>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import nfBreadcrumb from '@/component/breadcrumb/breadcrumb';

export default {
  name: 'patentSearch',
  data() {
    return {
      showArea: false, // 地区选择框
      // 查询类型
      navList: [
        { text: '全部', isChecked: true },
        { text: '标题/摘要', isChecked: false },
        { text: '申请人', isChecked: false },
        { text: '发明人', isChecked: false },
        { text: '代理机构/代理人', isChecked: false },
        { text: '专利号码', isChecked: false }
      ],
      // 地区选择
      //-----
      checkAll_all: false, // 全部
      showCheck: true,
      checkAll_one: false,
      area_one: [
        { value: 'cn', name: '中国大陆' },
        { value: 'hk', name: '香港' },
        { value: 'mo', name: '澳门' },
        { value: 'tw', name: '台湾' }
      ],
      //-----
      checkAll_two: false,
      area_two: [
        { value: 'ep', name: 'EPO' },
        { value: 'wipo', name: 'WIPO' },
        { value: 'us', name: '美国' },
        { value: 'jp', name: '日本' },
        { value: 'kr', name: '韩国' },
        { value: 'gb', name: '英国' },
        { value: 'fr', name: '法国' },
        { value: 'de', name: '德国' },
        { value: 'ru', name: '俄罗斯' }
      ],
      //-----
      checkAll_three: false,
      area_three: [
        { value: 'at', name: '奥地利' },
        { value: 'au', name: '澳大利亚' },
        { value: 'be', name: '比利时' },
        { value: 'nl', name: '荷兰' },
        { value: 'ca', name: '加拿大' },
        { value: 'es', name: '西班牙' },
        { value: 'mx', name: '墨西哥' }
      ],
      // 查询参数
      params: {
        kw: '', // 关键字
        scope: '0', // 查询范围
        checked_one: ['cn'], // 地区一
        checked_two: [], // 地区二
        checked_three: [] // 地区三
      }
    };
  },
  methods: {
    // 切换类型
    handleSwitch(index) {
      this.params.scope = index;
      this.navList.forEach(item => {
        item.isChecked = false;
      });
      this.navList[index].isChecked = true;
    },
    // 地区选择
    handleCheckAllChange_all(val) {
      if (val) {
        this.showCheck = false;
        this.params.checked_one = [];
        this.params.checked_two = [];
        this.params.checked_three = [];
        this.checkAll_one = false;
        this.checkAll_two = false;
        this.checkAll_three = false;
      } else {
        this.showCheck = true;
      }
    },
    handleCheckAllChange_one(val) {
      this.params.checked_one = val ? ['cn', 'hk', 'mo', 'tw'] : [];
    },
    handleCheckedAreaChange_one(value) {
      const checkedCount = value.length;
      this.checkAll_one = checkedCount === this.area_one.length;
    },
    handleCheckAllChange_two(val) {
      this.params.checked_two = val
        ? ['ep', 'wipo', 'us', 'jp', 'kr', 'gb', 'fr', 'de', 'ru']
        : [];
    },
    handleCheckedAreaChange_two(value) {
      const checkedCount = value.length;
      this.checkAll_two = checkedCount === this.area_two.length;
    },
    handleCheckAllChange_three(val) {
      this.params.checked_three = val
        ? ['at', 'au', 'be', 'nl', 'ca', 'es', 'mx']
        : [];
    },
    handleCheckedAreaChange_three(value) {
      const checkedCount = value.length;
      this.checkAll_three = checkedCount === this.area_three.length;
    },
    // 点击搜索
    toPatentList() {
      this.params.checked_one = this.params.checked_one.join(',');
      this.params.checked_two = this.params.checked_two.join(',');
      this.params.checked_three = this.params.checked_three.join(',');
      this.$router.push({
        path: '/patentSearch/patentList',
        query: this.params
      });
    }
  },
  components: { nfBreadcrumb }
};
</script>

<style lang="less" scoped>
.wrap {
  background: url('~@/assets/images/property/hb-bg@2x.png') center;
  background-size: cover;
}
.titleWrap {
  text-align: center;
  color: #6188cf;
  .title {
    font-size: 34px;
    letter-spacing: 2px;
    font-weight: 900;
    padding-top: 40px;
    margin-bottom: 10px;
  }
  .EnTitle {
    font-weight: 900;
  }
  .nav {
    padding: 30px 0 20px 0;
    font-size: 18px;
    font-weight: 900;
    span {
      padding: 4px 20px;
      border-radius: 5px;
    }
    span.active {
      background: #6188cf;
      color: #fff;
    }
  }
}
.searchWrap {
  position: relative;
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 480px;
  padding-top: 20px;
  .bgWrap {
    position: relative;
    background: #eee;
    padding: 5px;
    .areaWrap {
      position: absolute;
      width: 1094px;
      background: #fff;
      left: 0;
      top: 54px;
      border: 1px solid #ccc;
      box-sizing: border-box;
      .el-row {
        padding: 20px;
        /deep/ .el-checkbox__label {
          font-size: 16px;
        }
      }
      .el-row:hover {
        background: #eee;
      }
    }
  }
}
/deep/ .el-input-group__prepend {
  padding: 0;
  width: 150px !important;
  box-sizing: border-box;
  background: url('~@/assets/images/property/地球@3x.png') no-repeat 10px center;
}
.areaFiltration {
  height: 100%;
  line-height: 48px;
  width: 100%;
  box-sizing: border-box;
  padding-left: 50px;
}
.searchWrap /deep/ .el-input-group__prepend {
  width: 120px;
}
.searchWrap /deep/ .el-input-group--append,
.searchWrap /deep/ .el-input__inner {
  height: 50px;
  font-size: 16px;
}
.searchWrap /deep/ .el-input-group__append {
  width: 60px;
  text-align: center;
  background: #6188cf;
  border-color: #6188cf;
  font-size: 20px;
  color: #fff;
}
.breadWrap {
  border-bottom: 1px solid #ccc;
  .el-breadcrumb {
    width: 1200px;
    margin: 20px auto;
    font-size: 16px;
  }
}
</style>
